import React from "react"

// Components
import Seo from "../components/seo"
import ThanksComponent from "./screens/request-a-demo/thank-you"
import BodyLanding from "../components/body-landing"

const ThankYou = ({ location }) => {
	const firstName = (location.state) ? location.state.first_name : ""
	return (
		<main>
			<ThanksComponent first_name={firstName} />
			<BodyLanding landingPage="request-a-demo" />
		</main>
	)
}

export default ThankYou

export const Head = () => (
	<Seo
	  title="Request a demo"
	  description="Ready to see how on-site PCR could help you and your clinic?"
	/>
  )
